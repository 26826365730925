$(function() {
  $(".filter [data-toggle]").on("click", function() {
    var thisBtn = $(this);
    if (thisBtn.attr("aria-expanded") === "true") {
      thisBtn.text(thisBtn.attr("data-more"));
    } else {
      thisBtn.text(thisBtn.attr("data-less"));
    }
  });
});
